import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LegitimationInfoComponent } from './components/legitimation-info/legitimation-info.component';
import { BankInfoComponent } from './components/bank-info/bank-info.component';
import { FinancialInfoComponent } from './components/financial-info/financial-info.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { ServiceInfoComponent } from './components/service-info/service-info.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SummaryComponent } from './components/summary/summary.component';
import { SalutationPipe } from './pipes/salutation.pipe';
import { OccupationTitlePipe } from './pipes/occupation-title.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { MaritalStatePipe } from './pipes/marital-state.pipe';
import { EducationPipe } from './pipes/education.pipe';
import { IdTypePipe } from './pipes/id-type.pipe';
import { OccupationPipe } from './pipes/occupation.pipe';
import { IndustryPipe } from './pipes/industry.pipe';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { PersonalInfoState } from './states/PersonalInfoState';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import {
  NgxsStoragePluginModule,
  NgxsStoragePluginOptions,
  StorageOption,
} from '@ngxs/storage-plugin';
import { ContactInfoState } from './states/ContactInfoState';
import { LegitimationInfoState } from './states/LegitimationInfoState';
import { BankInfoState } from './states/BankInfoState';
import { FinancialInfoState } from './states/FinancialInfoState';
import { SelectedProductState } from './states/SelectedProductState';
import { BasicInfoState } from './states/BasicInfoState';
import { ServiceInfoState } from './states/ServiceInfoState';
import { PaybackMethodPipe } from './pipes/payback-method.pipe';
import { PaybackPlanPipe } from './pipes/payback-plan.pipe';
import { SigningComponent } from './components/signing/signing.component';
import { CurrentApplicationState } from './states/CurrentApplicationState';
import { PaymentInfoState } from './states/PaymentInfoState';
import { HomeComponent } from './components/home/home.component';
import { ResultComponent } from './components/result/result.component';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';
import { GlobalLoaderHttpInterceptor } from './interceptors/global-loader-interceptor';
import { GlobalLoaderService } from './services/global-loader.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { CurrentErrorState } from './states/CurrentErrorState';
import { AcademicTitlePipe } from './pipes/academic-title.pipe';
import { AppState } from './states/AppState';
import { PostposedAcademicTitlePipe } from './pipes/postposed-academic-title.pipe';
import { ConditionPipe } from './pipes/condition.pipe';
import { HeaderComponent } from './components/header/header.component';
import { ProductPipe } from './pipes/product.pipe';
import { GuaranteeUploadComponent } from './components/guarantee-upload/guarantee-upload.component';

export const STORAGE_CONFIG: NgxsStoragePluginOptions = {
  storage: StorageOption.SessionStorage,
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloak,
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'login-required',
      },
    });
}

@NgModule({
  declarations: [
    GuaranteeUploadComponent,
    AppComponent,
    PersonalInfoComponent,
    ContactInfoComponent,
    LegitimationInfoComponent,
    BankInfoComponent,
    FinancialInfoComponent,
    ProductSelectionComponent,
    ServiceInfoComponent,
    FileUploadComponent,
    SummaryComponent,
    SalutationPipe,
    OccupationTitlePipe,
    CountryPipe,
    MaritalStatePipe,
    EducationPipe,
    IdTypePipe,
    OccupationPipe,
    IndustryPipe,
    PaybackMethodPipe,
    PaybackPlanPipe,
    SigningComponent,
    HomeComponent,
    ResultComponent,
    GlobalLoaderComponent,
    AcademicTitlePipe,
    PostposedAcademicTitlePipe,
    ConditionPipe,
    HeaderComponent,
    ProductPipe,
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    CurrencyMaskModule,
    NgxsModule.forRoot([
      CurrentErrorState,
      PersonalInfoState,
      ContactInfoState,
      LegitimationInfoState,
      BankInfoState,
      FinancialInfoState,
      SelectedProductState,
      PaymentInfoState,
      BasicInfoState,
      ServiceInfoState,
      CurrentApplicationState,
      AppState,
    ]),
    NgxsEmitPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(STORAGE_CONFIG),
  ],
  providers: [
    ConditionPipe,
    ProductPipe,
    GlobalLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalLoaderHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
