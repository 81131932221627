import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { MaritalState, MaritalStates } from 'src/app/dtos/MaritalState';
import { OccupationTitles } from 'src/app/dtos/OccupationTitle';
import { Salutation, Salutations } from 'src/app/dtos/Salutation';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import * as countryDataFromJson from '../../../assets/data/country_iso_code.json';
import * as citizenshipFromJson from '../../../assets/data/citizenship_country_iso_code.json';
import { CountryModel } from 'src/app/dtos/CountryModel';
import { Educations } from 'src/app/dtos/Education';
import { Validators } from '@angular/forms';
import { isAfter, isBefore } from 'src/app/validators/CustomValidator';
import { PersonalInfoRequest } from 'src/app/dtos/PersonalInfoRequest';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { Router } from '@angular/router';
import { FormUtils } from 'src/app/utils/FormUtil';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { PersonalInfoState } from 'src/app/states/PersonalInfoState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { Select } from '@ngxs/store';
import { Observable, combineLatestWith } from 'rxjs';
import { AcademicTitles } from 'src/app/dtos/AcademicTitle';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { PostposedAcademicTitles } from 'src/app/dtos/PostposedAcademicTtile';
import { BasicInfoRequest } from 'src/app/dtos/BasicInfoRequest';
import { BasicInfoState } from 'src/app/states/BasicInfoState';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  @Emitter(PersonalInfoState.updatePersonalInfo)
  updatePersonalInfo$!: Emittable<PersonalInfoRequest>;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(PersonalInfoState.getPersonalInfo)
  getPersonalInfo$!: Observable<PersonalInfoRequest>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(BasicInfoState.getBasicInfo)
  getBasicInfo$!: Observable<BasicInfoRequest>;
  basicInfo: BasicInfoRequest = {};
  @Emitter(BasicInfoState.updateBasicInfo)
  updateBasicInfo$!: Emittable<BasicInfoRequest>;
  basicInforRequest: BasicInfoRequest = {};

  personalInfoForm: FormGroup = {} as FormGroup;
  maritalStates = MaritalStates;
  salutations = Salutations;
  occupationTitles = OccupationTitles;
  academicTitles = AcademicTitles;
  postposedAcademicTitles = PostposedAcademicTitles;
  educations = Educations;
  countries: any = (countryDataFromJson as any).default;
  citizenshipCountries: any = (citizenshipFromJson as any).default;
  latestPossibleBirthday: Date = new Date();
  today: Date = new Date();
  earliestDate: Date = new Date('1900-01-01');
  inAtSinceRequired: boolean = false;
  backendError: boolean = false;
  submitText: string = 'Weiter';
  showEmbossingInfo1: boolean = false;
  showEmbossingInfo2: boolean = false;
  showOwnDemandInfo: boolean = false;

  personalInfoRequest: PersonalInfoRequest = {};
  patchApplicationRequest: PatchApplicationRequest = {};

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.PERSONAL_INFO;
    this.updateAppState$.emit(this.appState);

    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }

    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.latestPossibleBirthday.setFullYear(
      this.latestPossibleBirthday.getFullYear() - 14
    );

    this.personalInfoForm = this.formBuilder.group({
      ownDemand: [null, [Validators.requiredTrue]],
      academicTitle: [null],
      occupationTitle: [null],
      salutation: [null, [Validators.required]],
      firstName: [
        null,
        [
          Validators.required,
          Validators.maxLength(26),
          Validators.pattern(`^[A-Za-zÄäÖöÜüß -]+$`),
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.maxLength(26),
          Validators.pattern(`^[A-Za-zÄäÖöÜüß -]+$`),
        ],
      ],
      birthday: [
        null,
        [
          Validators.required,
          isBefore(this.latestPossibleBirthday),
          isAfter(this.earliestDate),
        ],
      ],
      citizenship: ['AT', [Validators.required]],
      inAtSince: [null],
      maritalState: [null, [Validators.required]],
      education: [null, [Validators.required]],
      postposedAcademicTitle: [null],
      embossingLine1: [
        null,
        [
          Validators.required,
          Validators.pattern(`^[./,&A-Z0-9 -]+$`),
          Validators.maxLength(26),
        ],
      ],
      embossingLine2: [
        null,
        [Validators.pattern(`^[./,&A-Z0-9 -]+$`), Validators.maxLength(26)],
      ],
    });

    this.personalInfoForm.controls['firstName'].valueChanges
      .pipe(
        combineLatestWith(
          this.personalInfoForm.controls['lastName'].valueChanges
        )
      )
      .subscribe({
        next: ([firstName, lastName]) => {
          let preparedFirstName = this.prepareForEmbossing(firstName);
          let prepardLastName = this.prepareForEmbossing(lastName);
          let line1 = preparedFirstName + ' ' + prepardLastName;
          if (line1.length <= 26 && line1.length > 1) {
            this.personalInfoForm.controls['embossingLine1'].patchValue(line1);
            this.personalInfoForm.controls['embossingLine2'].patchValue(null);
          } else {
            this.personalInfoForm.controls['embossingLine1'].patchValue(
              preparedFirstName
            );
            this.personalInfoForm.controls['embossingLine2'].patchValue(
              prepardLastName
            );
          }
        },
      });

    this.personalInfoForm.controls['citizenship'].valueChanges.subscribe(
      (data) => {
        const inAtSince = this.personalInfoForm.controls['inAtSince'];

        if (data === 'AT') {
          this.inAtSinceRequired = false;
          inAtSince.clearValidators();
          inAtSince.setValue(null);
        } else {
          this.inAtSinceRequired = true;
          inAtSince.setValidators([
            Validators.required,
            isBefore(this.today),
            isAfter(this.earliestDate),
          ]);
        }

        inAtSince.updateValueAndValidity();
      }
    );

    this.getBasicInfo$.subscribe((value) => {
      if (value.ownDemand != undefined) {
        this.personalInfoForm.controls['ownDemand'].patchValue(value.ownDemand);
      }
    });

    this.getPersonalInfo$.subscribe((value) => {
      if (value.firstName != undefined) {
        this.personalInfoForm.controls['firstName'].patchValue(value.firstName);
      }
      if (value.academicTitle != undefined) {
        this.personalInfoForm.controls['academicTitle'].patchValue(
          value.academicTitle
        );
      }
      if (value.occupationTitle != undefined) {
        this.personalInfoForm.controls['occupationTitle'].patchValue(
          value.occupationTitle
        );
      }
      if (value.salutation != undefined) {
        this.personalInfoForm.controls['salutation'].patchValue(
          value.salutation
        );
      }
      if (value.lastName != undefined) {
        this.personalInfoForm.controls['lastName'].patchValue(value.lastName);
      }
      if (value.birthday != undefined) {
        this.personalInfoForm.controls['birthday'].patchValue(value.birthday);
        this.personalInfoForm.controls['birthday'].disable();
      }
      if (value.citizenship != undefined) {
        this.personalInfoForm.controls['citizenship'].patchValue(
          value.citizenship
        );
        this.personalInfoForm.controls['citizenship'].disable();
      }
      if (value.inAtSince != undefined) {
        this.personalInfoForm.controls['inAtSince'].patchValue(value.inAtSince);
      }
      if (value.martialState != undefined) {
        this.personalInfoForm.controls['maritalState'].patchValue(
          value.martialState
        );
      }
      if (value.education != undefined) {
        this.personalInfoForm.controls['education'].patchValue(value.education);
      }
      if (value.postposedAcademicTitle != undefined) {
        this.personalInfoForm.controls['postposedAcademicTitle'].patchValue(
          value.postposedAcademicTitle
        );
      }
      if (value.embossingLine1 != undefined) {
        this.personalInfoForm.controls['embossingLine1'].patchValue(
          value.embossingLine1
        );
      }
      if (value.embossingLine2 != undefined) {
        this.personalInfoForm.controls['embossingLine2'].patchValue(
          value.embossingLine2
        );
      }
    });
  }

  prepareForEmbossing(value: string): string {
    value = value.toUpperCase();
    value = value.replaceAll('Ä', 'AE');
    value = value.replaceAll('Ö', 'OE');
    value = value.replaceAll('Ü', 'UE');
    return value;
  }

  onSubmit(): void {
    this.basicInforRequest = {
      ownDemand: this.personalInfoForm.controls['ownDemand'].value,
    };
    this.personalInfoRequest = {
      academicTitle: this.personalInfoForm.controls['academicTitle'].value,
      postposedAcademicTitle:
        this.personalInfoForm.controls['postposedAcademicTitle'].value,
      occupationTitle: this.personalInfoForm.controls['occupationTitle'].value,
      salutation: this.personalInfoForm.controls['salutation'].value,
      firstName: this.personalInfoForm.controls['firstName'].value,
      lastName: this.personalInfoForm.controls['lastName'].value,
      birthday: this.personalInfoForm.controls['birthday'].value,
      citizenship: this.personalInfoForm.controls['citizenship'].value,
      inAtSince: this.personalInfoForm.controls['inAtSince'].value,
      martialState: this.personalInfoForm.controls['maritalState'].value,
      education: this.personalInfoForm.controls['education'].value,
      embossingLine1: this.personalInfoForm.controls['embossingLine1'].value,
      embossingLine2: FormUtils.handleClearInput(
        this.personalInfoForm.controls['embossingLine2'].value
      )!,
    };

    this.patchApplicationRequest = {
      basicInfo: this.basicInforRequest,
      personalInfo: this.personalInfoRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updatePersonalInfo$.emit(this.personalInfoRequest);
          this.updateBasicInfo$.emit(this.basicInforRequest);

          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('contact-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  onEmbossing1Change(event: any) {
    this.personalInfoForm.controls['embossingLine1'].patchValue(
      event.target.value.toUpperCase()
    );
  }
  onEmbossing2Change(event: any) {
    this.personalInfoForm.controls['embossingLine2'].patchValue(
      event.target.value.toUpperCase()
    );
  }

  back(): void {
    this.router.navigateByUrl('');
  }
}
